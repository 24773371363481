html, body {
  height: 100%
}

body {
  display: flex;
  flex-direction: column;
  font-size: 100%;
}

div#root{
  display: flex;
  flex-direction: column;
  height: 100%;
}
@font-face {
  font-family: "campton-book";
  src: url("./assets/fonts/campton-book.otf"),
  url("./assets/fonts/campton-book.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "campton-light";
  src: url("./assets/fonts/campton-light.otf"),
  url("./assets/fonts/campton-light.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "campton-medium";
  src: url("./assets/fonts/campton-medium.otf"),
  url("./assets/fonts/campton-medium.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nantes-bold";
  src: url("./assets/fonts/nantes-web-bold.woff"),
  url("./assets/fonts/nantes-web-bold.woff");
  font-weight: normal;
  font-style: normal;
}

.MuiButton-contained.Mui-disabled {
  color: #fafafa !important;
  background-color: #c89dff !important;
}

svg {
  overflow: visible;
}

.icon_account:hover {
  fill: #6B2ACC;
}
