.carousel-root {
  min-height: '400px';
}


.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
  background-color: transparent;
  right: -0.5vw;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
  background-color: transparent;
  left: -0.5vw;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1;
  filter: drop-shadow(0px 1px 2px black);
}

.carousel .control-next.control-arrow:before {
  content: url('../assets/icons/rightArrowWhite.svg') !important;
  border: none !important;
}

.carousel .control-prev.control-arrow:before {
  content: url('../assets/icons/leftArrowWhite.svg') !important;
  border: none !important;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    margin: -64px 0;
  }
}

@media (max-width: 960px) {
  .carousel .control-dots {
    margin: -25px 0;
  }
}

@media (max-width: 960px) {
  .carousel .control-next.control-arrow:before {
    content: '' !important;
    border: none !important;
  }

  .carousel .control-prev.control-arrow:before {
    content: '' !important;
    border: none !important;
  }
}

.carousel .control-dots .dot {
  width: 38px;
  height: 2px;
  margin: 0 5px;
  border-radius: 25px;
}

.carousel .slide{ background: none; }

